import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';

function ControlledCarousel({ slider }) {
    const settings = {
        interval: 3000,
        wrap: true,
        touch: true,
        keyboard: true,
        controls: false,
    };

    return (
        <>
            {slider && slider.length > 0 && (
                <Carousel {...settings} className="staffSlider">
                    {slider.map((e, index) => (
                        <Carousel.Item key={index}>
                            <div style={{ backgroundImage: `url(${e.brImage})` ,height:'700px'}} >
                                <div className="caption-info">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-8 col-md-offset-2">
                                                <div className="caption-info-inner text-center">
                                                    <h1 className="animated fadeInDown">{e.brTitle}</h1>
                                                    <p className="animated fadeInUp" style={{color:'#fff'}}>{e.brSubtitle}  </p>
                                                    {/* <Link
                                                        to="#"
                                                        className="animated fadeInUp btn btn-default page-scroll"
                                                    >
                                                        Read More
                                                    </Link> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            )
            }
        </>
    );
}

export default ControlledCarousel;