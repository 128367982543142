import React from 'react'

export default function AboutUs({ data,webpreview }) {


    return (
        <>
            {/* About Us */}
            <section id="about-us">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <div className="about-block wow fadeInLeft" id="about-carousel">
                                <div className="about-thumb">
                                    <img src={data && data.abimage ? data.abimage : "img/about-us/1.jpg"} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6" >
                        <h2 style={{color:`${webpreview && webpreview.Header_Fonts ? webpreview.Header_Fonts :""}`}}>{data && data.abtitle ? data.abtitle :"About Us"}</h2>
                            <div className="content-right wow fadeInRight" style={{maxHeight:'225px'}}>
                                <p>   {data && data.abstory}  </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End About Us */}
        </>
    )
}
