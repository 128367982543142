import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export default function Tooltips({info,data}) {
  return (
    <>
     {['top'].map((placement) => (
        <OverlayTrigger
          key={placement}
          placement='top'
          overlay={
            <Tooltip id={`tooltip-${placement}`} className="custom-tooltip" >
              {/* <strong>{info} </strong> */}
              {info}
            </Tooltip>
          }
        >
          <p className="InfoIcon" id="toolipdata" style={{margin:'0px', background:'none'}} variant="secondary" >{data}</p>
        </OverlayTrigger>
      ))}
    </>
  )
}
