import React, { useContext, useState } from 'react'
import ReactModal from 'react-modal'
import Loginmodals from './Loginmodals';
import { MyContext } from '../App';

export default function ProductPopup({ e, isopen, isClose, webpreview }) {
    const { fullInfo } = useContext(MyContext);

    const [loginOpen, setLoginOpen] = useState(false);
    const closeLogin = () => { setLoginOpen(false); }

    const customStyles = {
        content: {
            disply: "flex",
            with: '75%',
            margin: 'auto'
        },
    };


    return (
        <>
            <ReactModal
                isOpen={isopen}
                className="imagepopup"
                style={customStyles}
                contentLabel="Example Modal"          >

                <div className="logn-form p-3" style={{ display: 'flex' }}>
                    <img src={e && e.product_images[0] && e.product_images[0].image_name ? e.product_images[0].image_name : "img/trainers/1.jpg"} alt="" style={{ minHeight: '420px', maxHeight: '420px', minWidth: '380px', maxWidth: '380px' }} />
                    <div className='w-100' style={{ background: `${fullInfo && fullInfo.Background_for_Pop_windows ? fullInfo.Background_for_Pop_windows : "#fff"}`}}>
                        <button type="button" class="close pr-4 mt-2" aria-label="Close" onClick={() => isClose(false)}
                            style={{ cursor: 'pointer' }}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>

                        <div className='mt-3'>
                            <div className='p-3 '>
                                <h3>{e && e.product_name}</h3>
                                <div style={{ maxHeight: '390px', overflowX: "auto" }}>
                                    <p>{e && e.product_description}</p>
                                </div>
                                <h5>Price : {e && e.product_price}</h5>
                            </div>
                        </div>
                        <div className='p-3' style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "2px" }}>
                            <h5>Login to Warrior to purchase products :</h5>
                            <button type='button' className='mr-3'
                                style={{ padding: "0px 30px", border: "2px solid", borderRadius: '20px', cursor: 'pointer', background: `${fullInfo && fullInfo.Borders_and_Buttons ? fullInfo.Borders_and_Buttons : "#0d92d1"}`, color: `${fullInfo && fullInfo.button_text_color ? fullInfo.button_text_color : "#fff"}` }}

                                onClick={() => setLoginOpen(true)}>login</button>
                        </div>
                    </div>
                </div>
            </ReactModal>

            <Loginmodals opens={loginOpen} isClose={closeLogin} webpreview={webpreview} />
        </>
    )
}
