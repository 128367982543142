import React, { useContext, useEffect } from 'react'
import './Commin.css';
import { MyContext } from '../App';
import { useNavigate } from 'react-router-dom';

export default function Commingsoon() {
    const {maindata} = useContext(MyContext);
    const navigate = useNavigate();

    useEffect(()=>{if(maindata){navigate("/")}},[maindata]);
  
    return (
        <>
        <div className='mainbody'>
            <div className="bgimg">
                <div className="middle">
                    <h1 className=' animated-text'>COMING SOON</h1>
                    <hr />
                </div>
            </div>
        </div>
        </>
    )
}
