import React, { useContext, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { MyContext } from '../App';
import Loginmodals from './Loginmodals';

export default function CommanImagePopup({ data, isopen, isClose, type }) {
    const { bzdata, fullInfo } = useContext(MyContext);
    const [loginOpen, setLoginOpen] = useState(false);
    const closeLogin = () => { setLoginOpen(false); }

    const customStyles = {
        content: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center horizontally
            justifyContent: "center", // Center vertically
            width: '100%',
            height: '100vh',
            margin: 'auto',
        },
    };
    return (
        <>
            <ReactModal
                isOpen={isopen}
                className="imagepopup"
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="row logn-form p-3" style={{ display: 'flex',overflow:'auto' }}>
                    <div className='col-md-4 text-center'>
                        <img src={data && data.image ? data.image : "img/trainers/1.jpg"} alt="" style={{ minHeight: '420px', maxHeight: '420px', minWidth: '380px', maxWidth: '380px' }} />
                        <h3>{data && data.name}</h3>
                    </div>

                    <div className='col-md-8 p-5 p-md-0' style={{ background: `${fullInfo && fullInfo.Background_for_Pop_windows ? fullInfo.Background_for_Pop_windows : "#fff"}` }}>

                        <button type="button" className="close pr-4 mt-2" aria-label="Close" style={{ cursor: 'pointer' }} onClick={() => isClose(false)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className='mt-3'>
                            <div className='p-3'>
                                <div style={{ maxHeight: '390px', overflowX: "auto" }}>
                                    <h5>{data && data.description}</h5>
                                </div>
                            </div>
                        </div>
                        <div className='p-3' style={{ display: 'flex', marginBottom: "2px" }}>
                            <div>
                                <h5> {type ? type : 'To Sign Up'}</h5>
                            </div>
                            <div>
                                <button type='button' className='ml-2'
                                    style={{
                                        padding: "0px 30px", border: "2px solid",
                                        borderRadius: '20px', cursor: 'pointer',
                                        background: `${fullInfo && fullInfo.Borders_and_Buttons ? fullInfo.Borders_and_Buttons : "#0d92d1"}`,
                                        color: `${fullInfo && fullInfo.button_text_color ? fullInfo.button_text_color : "#fff"}`
                                    }}
                                    onClick={() => setLoginOpen(true)}>
                                    login
                                </button>
                            </div>

                        </div>

                    </div>
                </div>
            </ReactModal>

            <Loginmodals opens={loginOpen} isClose={closeLogin} webpreview={fullInfo} />
        </>
    )
}
