import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import Loginmodals from '../../Mymodals/Loginmodals';
import CommanImagePopup from '../../Mymodals/CommanImagePopup';


export default function Services({ data, webpreview }) {
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    useEffect(() => {
        if (data && data.length > 0) {
            setIsDataLoaded(true);
        }
    }, [data]);

    const [isopen, setIsopen] = useState(false);
    const isClose = () => { setIsopen(false); }
    const [img, setImg] = useState();

    const imgPopupHndler = (data) => {
        const final = {
            "image": data.image || "img/services/2.jpg",
            "name": data.service_name,
            "description": data.description
        }
        setImg(final); setIsopen(true);
    }
    return (
        <>
            {/* Services */}
            <section id="Services">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="section-title text-center">
                                <h2 style={{ color: `${webpreview && webpreview.Header_Fonts ? webpreview.Header_Fonts : ""}` }}>Services</h2>
                                <small className='myline'></small>
                                <p>{webpreview && webpreview.ser_description ? webpreview.ser_description : "Invitamus me testatur sed"}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{justifyContent:'center'}}>
                        {data && data.length > 0 && data.map((e, i) => (                         
                            <div className="col-xs-4 col-sm-4 col-md-4" key={i} style={{ cursor: 'pointer' }} onClick={() => imgPopupHndler(e)}>
                                <div className="course-list wow fadeInUp">
                                    <div className="course-thumb">
                                        <img src={e.image ? e.image : "img/courses/1.jpg"} alt="" />
                                    </div>
                                    <div className="course-content">
                                        <h3>
                                            {e.service_name}
                                        </h3>
                                        <p>
                                            {e.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* {isDataLoaded && (
                        <OwlCarousel 
                            items={2}
                            slideBy={1}
                            className='owl-theme'
                            loop
                            margin={10}
                            nav={false}
                            dots={false}
                            autoplay={true}
                            responsive={{
                                0: { items: 1 },
                                768: { items: 2 }
                            }}>
                            {data.map((e) => (
                                <div className="service-list" style={{cursor:'pointer'}} key={e.id} onClick={()=>imgPopupHndler(e)}>
                                    <img src={e.image ? e.image : "img/services/2.jpg"} alt="" />
                                    <div className="service-content">
                                        <h3>{e.service_name}</h3>
                                        <p>{e.description}</p>
                                    </div>
                                </div>
                            ))}
                        </OwlCarousel>
                    )} */}
                </div>
            </section>

            <CommanImagePopup isopen={isopen} isClose={isClose} data={img} type="To book a service appointment log into your Warrior account: " />
            {/* <Loginmodals opens={isopen} isClose={isClose}  /> */}

        </>
    )
}
