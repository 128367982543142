import Swal from "sweetalert2";
import http from "../http";
import logo from "../Images/logo_white_web.png"
import axioshttp from "../axioshttp";



// import http from "../http";
// import Swal from "sweetalert2";
// import logo from "../Images/logo_white_web.png"
// import moment from "moment";


export const webpreviewinfo = async (data) => {
  try {
    const responce = await http.post("web/BusinessWebsite/webpreviewInfo", data);
    if (responce) {
      if(responce.data && responce.data.data && responce.data.data.Authorization){
      localStorage.setItem('Authorization', responce.data.data.Authorization);
    }
      return responce.data;
    }
  } catch (error) {
    alert(error);
  }
}

export const webpreviewInfoByHostName = async (data) =>{
  try {
    const responce = await http.post("web/BusinessWebsite/webpreviewInfoByHostName", data);
    if (responce) {
      if(responce.data && responce.data.data && responce.data.data.Authorization){
      localStorage.setItem('Authorization', responce.data.data.Authorization);
    }
      return responce.data;
    }
  } catch (error) {
    alert(error);
  }

}

export const class_list_with_image = async (data) => {
  try {
    const responce = await http.post("web/studio/class_list", data);
    if (responce) {
        return responce;
    }
  } catch (error) {
    alert(error);
  }
}

export const service_list_with_image = async (data) => {
  try {
    const responce = await http.post("web/studio/services_list", data);
    if (responce) {
        return responce;
    }
  } catch (error) {
    alert(error);
  }
}


export const contactMail = async (data) => {
  try {
    const responce = await http.post("web/BusinessWebsite/sendContactMail", data);
    if (responce) {
        return responce;
    }
  } catch (error) {
    alert(error);
  }
}

export const workshoplistdata = async (data) => {
  try {
    const responce = await http.post("web/BusinessWebsite/workshop_list", data);
    if (responce) {
        return responce;
    }
  } catch (error) {
    alert(error);
  }
}


export const getProducts = async (data) => {
  try {
    const responce = await http.post("web/product/web_products_list", data);
    if (responce) {
      return responce.data;
    }
  } catch (error) {
    alert(error);
  }
}

export const getInstructor = async (data) => {
  try {
    const responce = await http.post("web/studio/instructor_list", data);
    if (responce) {
      return responce.data;
    }
  } catch (error) {
    alert(error);
  }
}

export const passes_list = async (data) => {
  try {
    const responce = await http.post("web/studio/passes_list", data);
    if (responce) {
      return responce.data;
    }
  } catch (error) {
    alert(error);
  }
}

export const myclassList = async (data) => {
  try {
    const resp = await http.post("web/studio/web_class_list", data);
    if (resp) {
      return resp.data;
    }
  } catch (error) {
    alert(error);
  }
}


export const login = async (data) => {
  try {
    const resp = await axioshttp.post("/webservices/api/login", data);
    if (resp.data.status == 1) {
      const userData = resp.data.data;
      localStorage.setItem('Staffid', userData.id);
      localStorage.setItem("userDetail", JSON.stringify(userData));//w_ParentDetail
      localStorage.setItem("lat", resp.data.data.lat);
      localStorage.setItem("lang", resp.data.data.lang);
      localStorage.setItem("currentUserId", resp.data.data.id);
      localStorage.setItem("parentid", resp.data.data.id);
      localStorage.setItem("role_id", resp.data.data.role_id);
      localStorage.setItem('w_loginDetail', JSON.stringify(userData));
      localStorage.setItem('w_parentId', JSON.stringify(userData.id));
      localStorage.setItem('w_MemberDetail', JSON.stringify(userData.family_member));
      localStorage.setItem('w_ParentDetail', JSON.stringify(userData));

      return resp;
    } else {
      Swal.fire({
        html: `<img src=${logo} class="img-fluid d-block mx-auto mt-5" />
          <h4 class="title text-uppercase text-center mt-4 textWhite">
          Error !!!
        </h4>
        <p class="text-center my-4 textcolor">
        <small class="textWhite">
       ${resp.data.message} </small></p>
        `,
        showClass: {
          popup: 'custom-dialog-container',
        },
        customClass: {
          confirmButton: 'custom-button custom-button-size modalbtn',
        },
      });
    }
  } catch (error) {
    alert(error);
  }
}



export const getClass_list = async (data) => {
  try {
    const responce = await http.post("web/studio/today_class_list", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    }
  } catch (error) {
    alert(error);
  }
}


export const get_workshop_schedule_calendar = async (data) => {
  try {
    const responce = await http.post("web/studio/get_workshop_schedule_calendar", data);
    if (responce) {
      if (responce.data.status == 1) {
        return responce;
      }
    } else console.log("resp-->", responce);
  } catch (error) {
    alert(error);
  }
}

export const get_service_schedule_calendar = async (data) => {
  try {
    const resp = await http.post("web/studio/get_service_schedule_calendar", data);
    if (resp) {
      if (resp.data.status == 1) {
        return resp;
      }
    }
  } catch (error) {
    alert(error);
  }
}


