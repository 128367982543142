
import './App.css';
import Home from './Component/Home';
import 'animate.css';
import { createContext,  useState } from 'react';
import {HashRouter, Route, Routes } from 'react-router-dom';
import Commingsoon from './Component/Commingsoon';


const MyContext = createContext();

function App() {

  const [maindata, setMaindata] = useState();
  const [bzid, setBzid] = useState();
  const [fullInfo,setFullInfo] = useState();

  const MaindataHnadler = (data, id) => { setMaindata(data); setBzid(id); }
  const fullInfoHandler = (data) =>{setFullInfo(data)}

  return (
    <>
        <MyContext.Provider value={{ 'maindata': maindata, MaindataHnadler: MaindataHnadler,'fullInfoHandler':fullInfoHandler,"fullInfo":fullInfo }}>
        <HashRouter>
          <Routes>
            <Route path="/comming-soon" element={<Commingsoon />} />
              <Route path="/" element={<Home />} />
          </Routes>
        </HashRouter>
      </MyContext.Provider>
      {/* <MyContext.Provider value={{ 'maindata': maindata, MaindataHnadler: MaindataHnadler }}>
        <BrowserRouter>
          <Routes>
            <Route path="/comming-soon" element={<Commingsoon />} />
              <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </MyContext.Provider> */}
    </>
  );
}

export default App;
export { MyContext };
