import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import { service_list_with_image } from '../../Services/Services';
import CommanImagePopup from '../../Mymodals/CommanImagePopup';


export default function Myworkshopslist({ data, webpreview }) {
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [slideData, setSlideData] = useState([]);

    useEffect(() => {
        if (data && data.length > 0) {
            setIsDataLoaded(true);
            const resp = data.filter((e) => e.visibility === "yes");
            setSlideData(resp)
        }
    }, [data]);


    const [isopen, setIsopen] = useState(false);
    const isClose = () => { setIsopen(false); }
    const [img, setImg] = useState();

    const imgPopupHndler = (data) => {
        const final = {
            "image": data.image || "img/services/2.jpg",
            "name": data.name,
            "description": data.description
        }
        setImg(final); setIsopen(true);
    }



    return (
        <>
            {/* Services */}
            <section id="Workshops">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="section-title text-center">
                                <h2 style={{ color: `${webpreview && webpreview.Header_Fonts ? webpreview.Header_Fonts : ""}` }}>Workshops</h2>
                                <small className='myline'></small>
                                <p>{webpreview && webpreview.wrk_description ? webpreview.wrk_description : "Invitamus me testatur sed"}</p>

                            </div>
                        </div>
                    </div>
                    {/* {slideData && slideData.length <= 2 ?
                        <div className='row' style={{ justifyContent: "center" }}>
                            {
                                slideData.map((e, i) => (
                                    <div className='col-md-4' key={i}>
                                        <div className="service-list" key={i} style={{ cursor: 'pointer' }} onClick={() => imgPopupHndler(e)}>
                                            <img src={e.image ? e.image : "img/services/2.jpg"} alt="" />
                                            <div className="service-content">
                                                <h3>{e.name}</h3>
                                                <p>{e.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        :
                        isDataLoaded && (
                            <OwlCarousel
                                items={3}
                                slideBy={1}
                                className='owl-theme'
                                loop
                                margin={10}
                                nav={false}
                                dots={false}
                                autoplay={true}
                                responsive={{
                                    0: { items: 1 },
                                    768: { items: 2 }
                                }}>
                                {slideData.map((e, i) => (
                                    <div className="service-list" key={i} style={{ cursor: 'pointer' }} onClick={() => imgPopupHndler(e)}>
                                        <img src={e.image ? e.image : "img/services/2.jpg"} alt="" />
                                        <div className="service-content">
                                            <h3>{e.name}</h3>
                                            <p>{e.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        )
                    } */}

                    <div className="row" style={{ justifyContent: 'center' }}>
                        {slideData && slideData.length > 0 && slideData.map((e, i) => (
                            <div className="col-xs-6 col-sm-6 col-md-4 mb-3" key={i} style={{ cursor: 'pointer' }} onClick={() => imgPopupHndler(e)}>
                                <div className="course-list wow fadeInUp">
                                    <div className="course-thumb">
                                        <img src={e.image ? e.image : "img/courses/1.jpg"} alt="" />
                                    </div>
                                    <div className="course-content">
                                        <h3> {e.name}</h3>
                                        <p> {e.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </section>
            <CommanImagePopup isopen={isopen} isClose={isClose} data={img} type={'To sign up to a workshop log into your Warrior account:'} />
            {/* End Services */}
        </>
    )
}
