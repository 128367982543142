import moment from "moment";

const changeTimeStampToUTCFull = (time) => {
    const timeFormat = sTimeFormat();
    if (timeFormat == 24) {
      const info = moment.unix(time).format("YYYY-MM-DD H:mm");
      return info;
    } else {
      const zoneArea = moment.tz.guess();
      const info = moment.unix(time).tz(zoneArea).format("YYYY-MM-DD H:mm");
      return info;
    }
  }

  
const sTimeFormat = () => {
    return 12;
  }
  export const newUserRedirect = (type, id) => {
    window.location.href = process.env.REACT_APP_BASE_URL + "signin";
  }
  

export const changeTimeStampToUTCDate = (date) => {
    const info = moment.unix(date).format("LT");
    return info;
  }
  

  export const checkCurrentDateFilter = (scheduleDate, st = "", ed = "") => {
    const cur = moment().format("Y-MM-DD");
    const currentDate = new Date(cur);
    const scheduleCreate = new Date(scheduleDate);
  
    if (scheduleCreate >= currentDate) {
      const start = changeTimeStampToUTCFull(st);
      const end = changeTimeStampToUTCFull(ed);
      const currentTime = moment();
      const endTime = moment(end, "DD/MM/YYYY HH:mm a");
      if (currentTime.isAfter(end)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }