import React, { useContext, useEffect, useState } from 'react'
import ReactModal from 'react-modal';
import { Link, useNavigate } from 'react-router-dom';

import { MyContext } from '../App';
import CryptoJS from 'crypto-js';

const customStyles = {
    content: {
        disply: "flex",
        with: '25%',
        margin: 'auto'
    },
};


export default function Loginmodals({ opens, isClose }) {
    const { bzdata, fullInfo } = useContext(MyContext);
    const [businessData, setBusinessData] = useState();

    useEffect(() => {
        if (bzdata) { setBusinessData(bzdata); }
    }, [bzdata]);

    const [value, setValue] = useState({
        'email': localStorage.getItem('saveGmail'),
        'password': localStorage.getItem('savePassword')
    })
    const [error, setError] = useState({
        'email': '',
        'password': ''
    })

    const [passShow, setPassShow] = useState(false);
    const [clientremembercheck, setClientremembercheck] = useState(false);
    const navigate = useNavigate();
    const [loder, setLoder] = useState(false);


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'email') {
            const emailValue = value.replace(/^\s+/g, '');
            setValue((pre) => ({ ...pre, [name]: value }));
            setError((pre) => ({ ...pre, email: '' }))
        }

        if (name === 'password') {
            const emailValue = value.replace(/^\s+/g, '');
            setValue((pre) => ({ ...pre, [name]: value }));
            setError((pre) => ({ ...pre, email: '' }))
        }
    };

    useEffect(() => {
        if (localStorage.getItem('checked')) {
            setClientremembercheck(localStorage.getItem('checked'));
        }
        if (localStorage.getItem('saveGmail')) { setValue((pre) => ({ ...pre, email: localStorage.getItem('saveGmail') })) }
        if (localStorage.getItem('savePassword')) { setValue((pre) => ({ ...pre, password: localStorage.getItem('savePassword') })) }

    }, [])

    const checkedHandler = (e) => {
        const isChacked = e.target.checked;
        if (isChacked === true) {
            setClientremembercheck(true);
        } else {
            setClientremembercheck(false);
        }
    }


    const encryptData = (data) => {
        let secretKey = "warriorsms.com";
        const encrypted = CryptoJS.AES.encrypt(data, secretKey).toString();
        return encrypted;
    };



    const onlogin = async (e) => {
        e.preventDefault();
        const gmail = value.email;
        const pass = value.password;
        let isValid = 1;
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (!gmail) {
            setError((pre) => ({ ...pre, email: 'Email is required' }));
            isValid = 2;
        } else if (gmail && !emailPattern.test(gmail)) {
            setError((pre) => ({ ...pre, email: 'Email must be a valid email address' }));
            isValid = 3;
        }

        if (!pass) {
            setError((pre) => ({ ...pre, password: 'Password is required' }));
            isValid = 4;
        }

        if (isValid === 1) {
            const gmail1 = encryptData(gmail);
            const pass1 = encryptData(pass);

            var dataToSend = {
                key1: gmail1,
                key2: pass1
            };

            // Construct the query string
            var queryString = Object.keys(dataToSend).map(key => key + '=' + encodeURIComponent(dataToSend[key])).join('&');
            window.open(`${process.env.REACT_APP_BASE_URL}signin?` + queryString);

        }
    }


    return (
        <>
            <ReactModal
                isOpen={opens}
                onRequestClose={isClose}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="login-header  text-center mt-2 px-2">
                    <button type="button" className="close modalclose" onClick={() => isClose(false)}
                        style={{ cursor: 'pointer' }}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>             
                        <h2 className="title dark-color mb-2" style={{ color: `${fullInfo && fullInfo.Header_Fonts ? fullInfo.Header_Fonts : ""}` }}> Welcome</h2>
                      
                        <p className="brand-color3 px-1 font-14">Please login to the Warrior platform to sign up or make a purchase </p>
                   
                </div>

                <div className="logn-form mb-2">
                    <form onSubmit={onlogin}>
                        <div className="mb-2">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                                Email address <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="email"
                                name='email'
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                onChange={handleInputChange}
                            />
                            <small style={{ color: 'red' }}>{error && error.email}</small>
                            <div id="emailHelp" className="form-text">
                                We'll never share your email with anyone else.
                            </div>
                        </div>
                        <div className="mb-2">
                            <label htmlFor="exampleInputPassword1" className="form-label">
                                Password <span style={{ color: 'red' }}>*</span>
                            </label>
                            <input
                                type="password"
                                name='password'
                                className="form-control"
                                id="exampleInputPassword1"
                                onChange={handleInputChange}
                            />
                            <small style={{ color: 'red' }}>{error && error.password}</small>
                        </div>
                        <div className="py-3">
                            <button className="mybtn text-uppercase w-100"
                                style={{ padding: '5px', cursor: 'pointer', background: `${fullInfo && fullInfo.Borders_and_Buttons ? fullInfo.Borders_and_Buttons : "#0d92d1"}`, color: `${fullInfo && fullInfo.button_text_color ? fullInfo.button_text_color : "#fff"}` }}
                                type="submit"
                            >  login </button>
                        </div>
                        <div className="text-center">
                            <p className="font-18 brand-color3" > Don't have an account? <span> <Link to="https://warriorsms.com/registration" target='blank' className="brand-color1 header-css"> <span style={{ color: '#1c97d4' }}>Sign up</span> </Link></span></p>
                        </div>

                        <div className='row text-center'>
                            <p className='mt-3 pt-3' style={{ fontFamily: 'Arial, sans-serif' }}>On Your Phone?
                                <br />
                                Open or download your mobile app
                            </p>

                        </div>

                        <ul style={{ display: 'flex', justifyContent: 'center' }}>
                            <li style={{ marginRight: '20px' }}>
                                <a href="https://play.google.com/store/apps/details?id=com.warriorsms.user&amp;pcampaignid=web_share&amp;pli=1" target="blank">
                                    <img src="clientassets/img/google_play_btn.png" />
                                </a>
                            </li>
                            <li>
                                <a href="https://apps.apple.com/us/app/warrior-sms-client/id1532481648" target="blank">
                                    <img src="clientassets/img/app_store_btn.png" />
                                </a>
                            </li></ul>

                    </form>
                </div>
            </ReactModal>
        </>
    )
}
