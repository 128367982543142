import React, { useEffect, useState } from 'react'
import Loginmodals from '../../Mymodals/Loginmodals';
import OwlCarousel from 'react-owl-carousel';
import Tooltips from '../Tooltips';

export default function Pricing({ data, webpreview }) {
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        if (data && data.length > 0) {
            setIsDataLoaded(true);
        }
    }, [data]);
    const [isopen, setIsopen] = useState(false);
    const isClose = () => { setIsopen(false); }
    return (
        <>
            {/* Pricing */}
            <section id="Passes">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="section-title text-center">
                                <h2 style={{ color: `${webpreview && webpreview.Header_Fonts ? webpreview.Header_Fonts : ""}` }}>Passes</h2>
                                <small className='myline'></small>
                                <p>{webpreview && webpreview.pas_description ? webpreview.pas_description : "Invitamus me testatur sed"}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{justifyContent:'center'}}>
                        {data && data.length > 0 && data.map((e, i) => (
                            <div className="col-xs-3 col-sm-3 col-md-3 mb-3" key={i} >
                                <div className="price-table wow fadeInUp" key={i} style={{border:`2px solid${webpreview && webpreview.Borders_and_Buttons}` , borderRadius:'15px'}}>
                                    <p>{e && e.pass_type}</p>
                                    <span className='HeadingPara' style={{ color: 'black', fontSize: '20px', cursor: "pointer" }}>
                                        <Tooltips info={e.pass_name} data={e && e.pass_name} />
                                    </span>
                                    <div className="price" >
                                        <span style={{ background: `${webpreview && webpreview.Borders_and_Buttons ? webpreview.Borders_and_Buttons : "#0d92d1"}`, color: `${webpreview && webpreview.button_text_color ? webpreview.button_text_color : "#fff"}` }}>
                                       
                                            {e && e.amount ? `$${e.amount}` : ''}
                                            <br />
                                            <sub>plus tax</sub>
                                           
                                        </span>
                                    </div>
                                    <ul className="pricing-list">
                                        <Tooltips info={e.description} data={<li>{e && e.description}</li>} />
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='row mt-3'>
                        <div className='d-flex' style={{ justifyContent: 'center' }}>
                            <h5>To purchase a pass, log into Warrior</h5>

                            <button type='button' className="mybtn mrp ml-3" style={{ background: `${webpreview && webpreview.Borders_and_Buttons ? webpreview.Borders_and_Buttons : "#0d92d1"}`, color: `${webpreview && webpreview.button_text_color ? webpreview.button_text_color : "#fff"}` }} onClick={() => setIsopen(true)}>   Login  </button>
                        </div>


                    </div>
                </div>
            </section>
            <Loginmodals opens={isopen} isClose={isClose} />
            {/* End Pricing */}
        </>
    )
}
