import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";

export default function ControlledCarouselNew({ data, maindata }) {
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (data && data.length > 0) {
      setIsDataLoaded(true);
    }
  }, [data]);

  const hexToRgba = (hex, opacity) => {
    hex = hex.replace(/^#/, '');
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  return (
    <>
      {/* Services */}
      <section id="home" style={{ padding: "0px" }}>
        {isDataLoaded && (
          <OwlCarousel
            items={1}
            slideBy={1}
            className="owl-theme"
            loop
            nav={false}
            dots={true}
            autoplay={true}
            responsive={{
              0: { items: 1, margin: 10 },
              600: { items: 1, margin: 20 },
              1000: { items: 1, margin: 30, },
            }}
          >
            {data.map((e, i) => (
              <div
                id="banner"
                style={{
                  backgroundImage: `url(${e.brImage})`,
                  height: "100vh",
                }}
                key={i}
              >
                <div className="caption-info" >
                  <div className="container">
                    <div className="row" >
                      <div className="col-sm-12 col-md-8 col-md-offset-2"
                        style={{
                          background: maindata && maindata[`is_tr_color${1}`] === 'yes'
                            ? hexToRgba(maindata[`tr_color${1}`], maindata[`percentage${1}`] / 100)
                            : "",
                          zIndex: 1,
                        }}
                      >

                        <div className="parallax-bg-red py-4" style={{ position: "relative", zIndex: 999 }}>
                          <div className="caption-info-inner text-center" >
                            <h1
                              className="animated fadeInDown"
                              style={{
                                color:
                                  maindata &&
                                    maindata["banner_font_color" + (i + 1)]
                                    ? maindata["banner_font_color" + (i + 1)]
                                    : "#fff",
                              }}
                            >
                              {e.brTitle}
                            </h1>
                            <p
                              className="animated fadeInUp"
                              style={{
                                color:
                                  maindata && maindata["banner_tagline" + (i + 1)]
                                    ? maindata["banner_tagline" + (i + 1)]
                                    : "#fff",
                                fontSize: "22px",
                                marginBottom: '0px'
                              }}
                            >
                              {e.brSubtitle}
                              {e.showbnlogo == "yes" &&
                                <div className="BrandLogo" >
                                  <img
                                    src={
                                      maindata && maindata.logo
                                        ? maindata.logo
                                        : "img/logo.png"
                                    }
                                    className="BannerLogoImg"
                                    alt="Logo"
                                  />
                                </div>
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        )}
      </section >
    </>
  );
}
