import React, { useContext, useState } from 'react'
import { Link, animateScroll as scroll } from 'react-scroll';
import { MyContext } from '../App';
export default function Sidebar({ maindata }) {
  const { fullInfo } = useContext(MyContext);
  const [togal, setTogal] = useState(false);

  const toggleMenu = () => {
    setTogal(!togal);
    if (!togal) {
      const element = document.querySelector('navsupport');
      if(element){
        element.style.display='block';
      }

    }
  };


  const handleMouseEnter = (event) => {
    event.target.style.color = fullInfo && fullInfo.Header_Fonts; // Change color to red when hovered
    event.target.style.textDecoration = 'none';
    event.target.style.cursor = 'pointer';
  }

  const handleMouseLeave = (event) => {
    event.target.style.color = "#fff"; // Change color to red when hovered
    event.target.style.textDecoration = 'none';
  }


  const navcloser = (e) => {


  }

  return (
    <>
      <div className='navsupport' onClick={navcloser}>
      </div>
      <aside className={`menu ${togal ? 'animate__animated animate__fadeInLeft' : ''}`} id="menu" onClick={toggleMenu} style={{ left: togal ? "0px" : "-220px" }}>
        <a
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          href="#" className="mobile-menu-opener">
          <i className="fa fa-bars" />
        </a>
        <nav className="navbar navbar-default navbar-static-top">

          {/* Title Banners  / Contact Form  */}
          <div id="navbar">
            <div className="row" style={{ textAlign: "center" }} id="brname">
              <span>
                <img src={maindata && maindata.logo ? maindata.logo : "img/logo.png"} style={{ maxWidth: '70px' }} alt="Logo" />
                <h5>{maindata && maindata.business_name}</h5>
              </span>
            </div>
            <ul className="nav navbar-nav navbar-left mt-4">
              <li >
                <Link
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  to="home" smooth={true} duration={500}>
                  Home
                  {/* Title Banners */}
                </Link>
              </li>
              <li>
                <Link
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  to="about-us" smooth={true} duration={500}>About Us</Link>
              </li>
              {maindata && maindata.showInstructor === "yes" &&
                <li>
                  <Link
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    to="Instructors" smooth={true} duration={500}>Instructors</Link>
                </li>
              }
              {maindata && maindata.showClass === "yes" &&
                <>
                  <li>
                    <Link
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      to="Classes" smooth={true} duration={500}>Classes</Link>
                  </li>
                  <li>
                    <Link
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      to="Class_Schedule" smooth={true} duration={500}>Class Schedule</Link>
                  </li>
                </>
              }
              {maindata && maindata.showService === "yes" &&
                <li>
                  <Link
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    to="Services" smooth={true} duration={500}>Services</Link>
                </li>
              }
              {maindata && maindata.showPass === "yes" &&
                <li>
                  <Link
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    to="Passes" smooth={true} duration={500}>Passes</Link>
                </li>
              }

              {maindata && maindata.showWorkshop === "yes" &&
                <>
                  <li>
                    <Link
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      to="Workshops" smooth={true} duration={500}>Workshops</Link>
                  </li>
                  <li>
                    <Link
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      to="Workshops_Schedule" smooth={true} duration={500}>Workshops Schedule</Link>
                  </li>
                </>
              }
              {maindata && maindata.showProduct === "yes" &&
                <li>
                  <Link
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    to="Products" smooth={true} duration={500}>Products</Link>
                </li>
              }
              <li>
                <Link
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  to="Testimonials" smooth={true} duration={500}>Testimonials</Link>
              </li>

              <li>
                <Link
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  to="contact-us" smooth={true} duration={500}>Warrior Mobile App </Link>
              </li>
              <li>
                <Link
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  to="contact-us" smooth={true} duration={500}>Contact Details</Link>
              </li>
              {/* Add similar links for other sections */}
            </ul>
          </div>
        </nav>
      </aside>


    </>
  )
}
