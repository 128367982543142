import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';

export default function Testimonials({ data,webpreview }) {
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    useEffect(() => {
        if (data && data.length > 0) {
            setIsDataLoaded(true);
        }
    }, [data]);
    return (
        <>
            <section
                id="Testimonials"
                className="parallax-bg"
                style={{ backgroundImage: "url(img/testimonials/bg.jpg)" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="section-title text-center">
                                <h2 style={{color:`${webpreview && webpreview.Header_Fonts ? webpreview.Header_Fonts :""}`}}>Testimonials</h2>
                                <small className='myline'></small>
                                <p>What our clients say</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-8 col-md-offset-2">
                            <div
                                id="testimonial-carousel"
                                className="carousel slide"
                                data-ride="carousel"
                            >
                                <div className="carousel-inner">
                                    {isDataLoaded && (
                                        <OwlCarousel
                                            items={1}
                                            slideBy={1}
                                            loop
                                            nav={false}
                                            dots={false}
                                            autoplay={true}
                                        >
                                            {data.map((e) => (
                                                <div className={"item"} key={e.id}>
                                                    <div className="testimonial-item text-center" key={e.id}>
                                                        <div className="author">
                                                            <img src={e.image ? e.image : "img/testimonials/1.jpg"} alt="" />
                                                        </div>
                                                        <div className="author-comments">
                                                            <p>
                                                                {e.description}
                                                            </p>
                                                        </div>
                                                        <div className="designation">{e.provider}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </OwlCarousel>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Testimonials */}

        </>
    )
}
